<template>
  <div style="width: 100%; height: 240px">
    <div class="mo-wang-editor" ref="editor"></div>
  </div>
</template>

<script>
import wangEdit from "wangeditor";
// // api
import API from "@/api/modules/issue.js";
import AlertMenu from "@/fwb@/AlertMenu"; // 根据AlertMenu.js文件实际路径进行引入即可
export default {
  name: "editor",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: { required: true },
  },
  data() {
    return {
      h: "",
      url: "",
      placeholder: "请文明用语…",
      height: 160,
      editor: null,
      //配置富文本编辑器的菜单栏
      menu: [
        "undo", // 撤销
        "redo", // 重复
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "indent", // 缩进
        "lineHeight", // 行高
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        // "todo", // 待办
        "justify", // 对齐方式
        // "quote", // 引用
        "emoticon", //表情包
        "image", // 插入图片
        // "video", // 视频
        // "table", // 表格
        // "code", // 插入代码
        "splitLine", // 分割线
      ],
    };
  },
  watch: {
    value(val) {
      // 如果值不相等再进行赋值，避免多次赋值造成闪烁
      if (this.editor && val !== this.editor.txt.html()) {
        this.editor.txt.html(val);
      }
    },
  },
  methods: {
    initEditor() {
      let url = this.url;
      const editor = new wangEdit(this.$refs.editor);
      editor.config.placeholder = this.placeholder;
      editor.config.height = this.height;
      editor.config.menus = this.menu;
      //实现上传视频
      editor.config.customUploadVideo = async function (files, insertVideoFn) {
        let fd = new FormData();
        fd.append("file", files[0]);
        let res = await API.upload(fd).then((res) => {
          if (res.code == 1) {
            insertVideoFn(res.data.fullurl);
          }
        });
      };

      // 限制类型
      // editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 5 个图片

      //监听数据变化
      editor.config.onchange = this.handleContentChange;
      //上传图片
      editor.config.customUploadImg = async function (files, insertImgFn) {
        let fd = new FormData();
        fd.append("file", files[0]);
        let res = await API.upload(fd);
        if (res.code == 1) {
          //  res.data[0].url;
          insertImgFn(res.data.fullurl);
          // console.log(insertImgFn(this.url + res.data[0].url),'地址');
        } else {
          // console.log(res.msg, "失败??");
        }
      };
      editor.config.pasteFilter = true;
      var baseurl = sessionStorage.getItem("di");
      editor.config.emotions =  [{
            // tab 的标题
            title: '表情',
            // type -> 'emoji' / 'image'
            type: 'image',
            // content -> 数组
            content: [{
              'alt': '[微笑]',
              'src': `${baseurl}/assets/image/emojipack/${'weixiao'}.png`
            }, {
              'alt': '[撇嘴]',
              'src': `${baseurl}/assets/image/emojipack/${'piezui'}.png`
            }, {
              'alt': '[色]',
              'src': `${baseurl}/assets/image/emojipack/${'se'}.png`
            }, {
              'alt': '[发呆]',
              'src': `${baseurl}/assets/image/emojipack/${'fadai'}.png`
            }, {
              'alt': '[得意]',
              'src': `${baseurl}/assets/image/emojipack/${'deyi'}.png`
            }
              , {
              'alt': '[流泪]',
              'src': `${baseurl}/assets/image/emojipack/${'liulei'}.png`
            }, {
              "alt": "[害羞]",
              "src": `${baseurl}/assets/image/emojipack/${'haixiu'}.png`
            }, {
              "alt": "[闭嘴]",
              "src": `${baseurl}/assets/image/emojipack/${'bizui'}.png`
            }, {
              "alt": "[睡]",
              "src": `${baseurl}/assets/image/emojipack/${'shui'}.png`
            }, {
              "alt": "[大哭]",
              "src": `${baseurl}/assets/image/emojipack/${'daku'}.png`
            }, {
              "alt": "[尴尬]",
              "src": `${baseurl}/assets/image/emojipack/${'ganga'}.png`
            }, {
              "alt": "[发怒]",
              "src": `${baseurl}/assets/image/emojipack/${'fanu'}.png`
            }, {
              "alt": "[调皮]",
              "src": `${baseurl}/assets/image/emojipack/${'tioapi'}.png`
            }, {
              "alt": "[呲牙]",
              "src": `${baseurl}/assets/image/emojipack/${'ciya'}.png`
            }, {
              "alt": "[惊讶]",
              "src": `${baseurl}/assets/image/emojipack/${'jingya'}.png`
            }, {
              "alt": "[难过]",
              "src": `${baseurl}/assets/image/emojipack/${'nanguo'}.png`
            }, {
              "alt": "[囧]",
              "src": `${baseurl}/assets/image/emojipack/${'jiong'}.png`
            }, {
              "alt": "[抓狂]",
              "src": `${baseurl}/assets/image/emojipack/${'zhuakuang'}.png`
            }, 
            // {
            //   "alt": "[酷]",
            //   "src": `${baseurl}/assets/image/emojipack/${'ku'}.png`
            // }, {
            //   "alt": "[冷汗]",
            //   "src": `${baseurl}/assets/image/emojipack/${'lenghan'}.png`
            // }, 
            {
              "alt": "[吐]",
              "src": `${baseurl}/assets/image/emojipack/${'tu'}.png`
            }, {
              "alt": "[偷笑]",
              "src": `${baseurl}/assets/image/emojipack/${'touxiao'}.png`
            }, {
              "alt": "[愉快]",
              "src": `${baseurl}/assets/image/emojipack/${'yukuai'}.png`
            }, {
              "alt": "[白眼]",
              "src": `${baseurl}/assets/image/emojipack/${'baiyan'}.png`
            }, {
              "alt": "[傲慢]",
              "src": `${baseurl}/assets/image/emojipack/${'aoman'}.png`
            }, 
            // {
            //   "alt": "[饥饿]",
            //   "src": `${baseurl}/assets/image/emojipack/${'jie'}.png`
            // },
             {
              "alt": "[困]",
              "src": `${baseurl}/assets/image/emojipack/${'kun'}.png`
            }, {
              "alt": "[惊恐]",
              "src": `${baseurl}/assets/image/emojipack/${'jingkong'}.png`
            }, 
            // {
            //   "alt": "[流汗]",
            //   "src": `${baseurl}/assets/image/emojipack/${'liuhan'}.png`
            // }, 
            {
              "alt": "[憨笑]",
              "src": `${baseurl}/assets/image/emojipack/${'huaixiao'}.png`
            }, {
              "alt": "[悠闲]",
              "src": `${baseurl}/assets/image/emojipack/${'youxian'}.png`
            }, 
            // {
            //   "alt": "[奋斗]",
            //   "src": `${baseurl}/assets/image/emojipack/${'fendou'}.png`
            // }, 
            {
              "alt": "[咒骂]",
              "src": `${baseurl}/assets/image/emojipack/${'zhouma'}.png`
            }, {
              "alt": "[疑问]",
              "src": `${baseurl}/assets/image/emojipack/${'yiwen'}.png`
            }, {
              "alt": "[嘘]",
              "src": `${baseurl}/assets/image/emojipack/${'xu'}.png`
            }, 
            // {
            //   "alt": "[晕]",
            //   "src": `${baseurl}/assets/image/emojipack/${'yun'}.png`
            // }, {
            //   "alt": "[疯了]",
            //   "src": `${baseurl}/assets/image/emojipack/${'fengle'}.png`
            // }, 
            {
              "alt": "[哀]",
              "src": `${baseurl}/assets/image/emojipack/${'shuai'}.png`
            }, {
              "alt": "[骷髅]",
              "src": `${baseurl}/assets/image/emojipack/${'kulou'}.png`
            }, {
              "alt": "[敲打]",
              "src": `${baseurl}/assets/image/emojipack/${'qiaoda'}.png`
            }, {
              "alt": "[再见]",
              "src": `${baseurl}/assets/image/emojipack/${'zaijian'}.png`
            }, {
              "alt": "[擦汗]",
              "src": `${baseurl}/assets/image/emojipack/${'cahan'}.png`
            }, {
              "alt": "[抠鼻]",
              "src": `${baseurl}/assets/image/emojipack/${'koubi'}.png`
            }, {
              "alt": "[鼓掌]",
              "src": `${baseurl}/assets/image/emojipack/${'guzhang'}.png`
            }, 
            // {
            //   "alt": "[糗大了]",
            //   "src": `${baseurl}/assets/image/emojipack/${'qiudale'}.png`
            // }, 
            {
              "alt": "[坏笑]",
              "src": `${baseurl}/assets/image/emojipack/${'huaixiao'}.png`
            }, 
            // {
            //   "alt": "[左哼哼]",
            //   "src": `${baseurl}/assets/image/emojipack/${'zuohengheng'}.png`
            // }, 
            {
              "alt": "[右哼哼]",
              "src": `${baseurl}/assets/image/emojipack/${'youhengheng'}.png`
            },
            // {
            //   "alt": "[哈欠]",
            //   "src": `${baseurl}/assets/image/emojipack/${'haqian'}.png`
            // }, 
            {
              "alt": "[鄙视]",
              "src": `${baseurl}/assets/image/emojipack/${'bishi'}.png`
            }, {
              "alt": "[委屈]",
              "src": `${baseurl}/assets/image/emojipack/${'weiqu'}.png`
            }, {
              "alt": "[快哭了]",
              "src": `${baseurl}/assets/image/emojipack/${'kauikule'}.png`
            }, {
              "alt": "[阴险]",
              "src": `${baseurl}/assets/image/emojipack/${'yinxian'}.png`
            }, {
              "alt": "[亲亲]",
              "src": `${baseurl}/assets/image/emojipack/${'qinqin'}.png`
            },
            //  {
            //   "alt": "[吓]",
            //   "src": `${baseurl}/assets/image/emojipack/${'xia'}.png`
            // },
             {
              "alt": "[可怜]",
              "src": `${baseurl}/assets/image/emojipack/${'kelian'}.png`
            },
            {
              "alt": "[笑脸]",
              "src": `${baseurl}/assets/image/emojipack/${'xianlian'}.png`
            },
            {
              "alt": "[生病]",
              "src": `${baseurl}/assets/image/emojipack/${'shengbing'}.png`
            },
            {
              "alt": "[脸红]",
              "src": `${baseurl}/assets/image/emojipack/${'lianhong'}.png`
            },
            {
              "alt": "[破涕为笑]",
              "src": `${baseurl}/assets/image/emojipack/${'potiweixiao'}.png`
            },
            {
              "alt": "[恐惧]",
              "src": `${baseurl}/assets/image/emojipack/${'kongju'}.png`
            },
            {
              "alt": "[失望]",
              "src": `${baseurl}/assets/image/emojipack/${'shiwang'}.png`
            },
            {
              "alt": "[无语]",
              "src": `${baseurl}/assets/image/emojipack/${'wuyu'}.png`
            },
            {
              "alt": "[嘿哈]",
              "src": `${baseurl}/assets/image/emojipack/${'heiha'}.png`
            },
            {
              "alt": "[捂脸]",
              "src": `${baseurl}/assets/image/emojipack/${'wulian'}.png`
            },
            {
              "alt": "[奸笑]",
              "src": `${baseurl}/assets/image/emojipack/${'jianxiao'}.png`
            },
            {
              "alt": "[机智]",
              "src": `${baseurl}/assets/image/emojipack/${'jizhi'}.png`
            },
            {
              "alt": "[皱眉]",
              "src": `${baseurl}/assets/image/emojipack/${'zhoumei'}.png`
            },
            {
              "alt": "[耶]",
              "src": `${baseurl}/assets/image/emojipack/${'ye'}.png`
            },
            {
              "alt": "[吃瓜]",
              "src": `${baseurl}/assets/image/emojipack/${'chigua'}.png`
            },
            {
              "alt": "[加油]",
              "src": `${baseurl}/assets/image/emojipack/${'jiayou'}.png`
            },
            {
              "alt": "[汗]",
              "src": `${baseurl}/assets/image/emojipack/${'han'}.png`
            },
            {
              "alt": "[天啊]",
              "src": `${baseurl}/assets/image/emojipack/${'tiana'}.png`
            },
            {
              "alt": "[Emm]",
              "src": `${baseurl}/assets/image/emojipack/${'Emm'}.png`
            },
            {
              "alt": "[社会社会]",
              "src": `${baseurl}/assets/image/emojipack/${'shehui'}.png`
            },
            {
              "alt": "[旺柴]",
              "src": `${baseurl}/assets/image/emojipack/${'wangchai'}.png`
            },
            {
              "alt": "[好的]",
              "src": `${baseurl}/assets/image/emojipack/${'haode'}.png`
            },
            {
              "alt": "[打脸]",
              "src": `${baseurl}/assets/image/emojipack/${'dalian'}.png`
            },
            {
              "alt": "[哇]",
              "src": `${baseurl}/assets/image/emojipack/${'wa'}.png`
            },
            {
              "alt": "[翻白眼]",
              "src": `${baseurl}/assets/image/emojipack/${'fanbaiyan'}.png`
            },
            {
              "alt": "[666]",
              "src": `${baseurl}/assets/image/emojipack/${'666'}.png`
            },
            {
              "alt": "[让我看看]",
              "src": `${baseurl}/assets/image/emojipack/${'rangwokankan'}.png`
            },
            {
              "alt": "[叹气]",
              "src": `${baseurl}/assets/image/emojipack/${'tanqi'}.png`
            },
            {
              "alt": "[苦涩]",
              "src": `${baseurl}/assets/image/emojipack/${'kuse'}.png`
            },
            {
              "alt": "[裂开]",
              "src": `${baseurl}/assets/image/emojipack/${'liekai'}.png`
            },
            {
              "alt": "[嘴唇]",
              "src": `${baseurl}/assets/image/emojipack/${'zuichun'}.png`
            },
            {
              "alt": "[爱心]",
              "src": `${baseurl}/assets/image/emojipack/${'aixin'}.png`
            },
            {
              "alt": "[心碎]",
              "src": `${baseurl}/assets/image/emojipack/${'xinsui'}.png`
            },
            {
              "alt": "[拥抱]",
              "src": `${baseurl}/assets/image/emojipack/${'yongbao'}.png`
            },
            {
              "alt": "[强]",
              "src": `${baseurl}/assets/image/emojipack/${'qiang'}.png`
            },
            {
              "alt": "[弱]",
              "src": `${baseurl}/assets/image/emojipack/${'ruo'}.png`
            },
            {
              "alt": "[握手]",
              "src": `${baseurl}/assets/image/emojipack/${'woshou'}.png`
            },
            {
              "alt": "[胜利]",
              "src": `${baseurl}/assets/image/emojipack/${'shengli'}.png`
            },
            {
              "alt": "[抱拳]",
              "src": `${baseurl}/assets/image/emojipack/${'baoquan'}.png`
            },
            {
              "alt": "[勾引]",
              "src": `${baseurl}/assets/image/emojipack/${'gouyin'}.png`
            },
            {
              "alt": "[拳头]",
              "src": `${baseurl}/assets/image/emojipack/${'quantou'}.png`
            },
            {
              "alt": "[ok]",
              "src": `${baseurl}/assets/image/emojipack/${'ok'}.png`
            },
            {
              "alt": "[合十]",
              "src": `${baseurl}/assets/image/emojipack/${'heshi'}.png`
            },
            {
              "alt": "[啤酒]",
              "src": `${baseurl}/assets/image/emojipack/${'pijiu'}.png`
            },
            {
              "alt": "[咖啡]",
              "src": `${baseurl}/assets/image/emojipack/${'kafei'}.png`
            },
            {
              "alt": "[蛋糕]",
              "src": `${baseurl}/assets/image/emojipack/${'dangao'}.png`
            },
            {
              "alt": "[玫瑰]",
              "src": `${baseurl}/assets/image/emojipack/${'meigui'}.png`
            },
            {
              "alt": "[凋谢]",
              "src": `${baseurl}/assets/image/emojipack/${'diaoxie'}.png`
            },
            {
              "alt": "[菜刀]",
              "src": `${baseurl}/assets/image/emojipack/${'caidao'}.png`
            },
            {
              "alt": "[炸弹]",
              "src": `${baseurl}/assets/image/emojipack/${'zhadan'}.png`
            },
            {
              "alt": "[便便]",
              "src": `${baseurl}/assets/image/emojipack/${'bianbian'}.png`
            },
            {
              "alt": "[月亮]",
              "src": `${baseurl}/assets/image/emojipack/${'yueliang'}.png`
            },
            {
              "alt": "[太阳]",
              "src": `${baseurl}/assets/image/emojipack/${'taiyang'}.png`
            },
            {
              "alt": "[庆祝]",
              "src": `${baseurl}/assets/image/emojipack/${'qingzhu'}.png`
            },
            {
              "alt": "[礼物]",
              "src": `${baseurl}/assets/image/emojipack/${'liwu'}.png`
            },
            {
              "alt": "[红包]",
              "src": `${baseurl}/assets/image/emojipack/${'hongbao'}.png`
            },
            {
              "alt": "[發]",
              "src": `${baseurl}/assets/image/emojipack/${'fa'}.png`
            },
            {
              "alt": "[福]",
              "src": `${baseurl}/assets/image/emojipack/${'fu'}.png`
            },
            {
              "alt": "[烟花]",
              "src": `${baseurl}/assets/image/emojipack/${'yanhua'}.png`
            },
            {
              "alt": "[猪]",
              "src": `${baseurl}/assets/image/emojipack/${'zhu'}.png`
            }
            ]
          }
        ]
      // 配置粘贴文本的内容处理
      // editor.config.pasteTextHandle = function (newHtml) {
      //   const str = newHtml.replace(/<[^>]+>/g, ""); // 截取html标签
      //   const text = str.replace(/&nbsp;/gi, ""); // 截取空格等特殊标签
      //   // 对粘贴的文本进行处理，然后返回处理后的结果
      //   return text;
      // };

      editor.menus.extend("alertMenu", AlertMenu); // 配置扩展的菜单
      editor.config.menus = editor.config.menus.concat("alertMenu");

      // 创建编辑器
      editor.create();
      // 赋予初始值
      editor.txt.html(this.value);

      this.editor = editor;
    },
    handleContentChange(newHtml) {
      this.h = newHtml;
      // console.log(this.h, '获得');
      // this.$emit("chang",newHtml);
      // this.$parent.chang(newHtml)
    },
    // 重新赋予html
    huodeGTML(html) {
      this.editor.txt.html(html);
    },
    // 外部插入图片(只允许插入一张)
    charutu(url) {
      // 获取图片地址
      var di = url;
      // 往富文本里插入图片
      this.editor.cmd.do(
        "insertHtml",
        '<img src="' + di + '" style="max-width:100%;"/>'
      );
      // // 获取富文本里的所有图片
      // let a = this.GetTheHTML();
      // // 判断富文本里是否存在图片
      // let b = a.search(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "") >= 0;
      // // b为fasle不存在
      // if (b == false) {
      //   // 不在执行
      //   return;
      // } else {
      //   // 如果存在图片则用正则匹配到图片,然后将它替换为新的图片
      //   let c = a.replace(
      //     /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
      //     `<img src="${di}" style="max-width:100%;"/>`
      //   );
      //   // 重新赋予富文本html
      //   this.huodeGTML(c);
      // }
    },
    // 获取html
    GetTheHTML() {
      return this.h;
    },
    /**
     * resultFiles 是 input 中选中的文件列表
     * insertImgFn 是获取图片 url 后，插入到编辑器的方法
     */
    async handleUploadImage(resultFiles, insertImgFn) {
      const imgUrl = await this.getObjectURL(resultFiles[0]);
      // 上传图片，返回结果，将图片插入到编辑器中
      insertImgFn(imgUrl);
    },

    async handleUploadVideo(resultFiles, insertVideoFn) {
      const videoUrl = await this.getObjectURL(resultFiles[0]);
      // 上传视频，返回结果，将视频插入到编辑器中
      insertVideoFn(videoUrl);
    },

    /**
     * 将file对象或blob对象转url
     * ref: https://www.cnblogs.com/linxue/p/9469715.html
     */
    getObjectURL(obj) {
      var url = null;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(obj);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(obj);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(obj);
      }
      return url;
    },
  },
  mounted() {
    let baseUrl = process.env.VUE_APP_BASE2_API;
    this.url = baseUrl;
    this.initEditor();
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style lang="scss" scoped>
.mo-wang-editor {
  // 工具栏icon
  .w-e-toolbar .w-e-menu {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  // 工具栏提示文字
  .w-e-menu-tooltip {
    font-size: 12px;
  }
  ::v-deep(.w-e-text img) {
    max-width: 100%;
  }
  // 工具栏边框
  .w-e-toolbar {
    border: 1px solid #dcdfe6 !important;
    border-bottom: none !important;
  }

  // 编辑区域边框
  .w-e-text-container {
    border: 1px solid #dcdfe6 !important;
  }

  // 默认提示文字
  .w-e-text-container .placeholder {
    font-size: 12px;
    color: #c1c5cd;
  }

  // 上传按钮
  .w-e-menu .w-e-panel-container .w-e-up-img-container .w-e-up-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    font-size: 24px;
  }
}

:deep(.w-e-text-container) {
  min-height: auto !important;
  z-index: 30 !important;
}

// /deep/ p{
//   span{
//     display: inline-block !important;
//   }
// }
</style>
